<template>
  <vs-row class="full-height" :style="{ margin: '0px' }">
    <vs-row vs-justify="center">
      <vs-col
        type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
      >
        <div>
          <div class="main-title">{{ this.title }}</div>
          <div v-for="(quizList, index) in this.list" :key="index">
            <div class="insights-main" v-if="index === 0">Questions</div>
            <div class="insights-main" v-if="index === 1">Results</div>
            <hr/>

            <div class="ul">

              <div class="li" v-bind:class="row.sub === undefined ? 'boxes' : ''" v-for="(row, rowIndex) in quizList" :key="rowIndex">
                <div v-if="row.sub !== undefined" class="secondary-title">{{row.title}}</div>

                <div v-if="row.sub === undefined" v-bind:class="row.image === '' ? 'center-box' : ''">
                  <div class="img" v-if="row.image !== ''"><img v-bind:src="row.image_full"/></div>
                  <div class="spacing" v-if="row.image !== ''"></div>
                  <div class="title">{{row.title}}</div>
                  <div class="answered">{{row.answered}}</div>
                </div>

                <div v-else class="ul">
                  <div class="li boxes" v-for="(rowSub, rowSubIndex) in row.sub" :key="rowSubIndex">
                    <div v-bind:class="rowSub.image === '' ? 'center-box' : ''">
                      <div class="img" v-if="rowSub.image !== ''"><img v-bind:src="rowSub.image_full"/></div>
                      <div class="spacing" v-if="rowSub.image !== ''"></div>
                      <div class="title">{{rowSub.title}}</div>
                      <div class="answered">{{rowSub.answered}}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </vs-col>
    </vs-row>
  </vs-row>
</template>
<style>
  .main-title{
    font-size: 25px;
    text-align: right;
    direction: rtl;
  }
  .insights-main{
    font-size: 22px;
    margin-top: 35px;
    text-transform: capitalize;
  }
  .ul{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 40px;
    direction: rtl;
  }
  .li{
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
  }
  .li.boxes{
    width: 300px;
    min-height: 300px;
  }
  .secondary-title{
    font-size: 18px;
    text-align: right;
    direction: rtl;
  }
  .li.boxes > div {
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10px;
    position: relative;
    border-radius: 10px;
  }
  .li .center-box{
    justify-content: center;
  }
  .li .spacing{
    margin-bottom: 15px;
  }
  .li .img{
    position: relative;
    display: block;
    width: 100%;
  }
  .li img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
  .li .title{
    font-size: 16px;
    text-align: right;
    direction: rtl;
  }
  .li .answered{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -15px;
    width: 40px;
    height: 40px;
    background: #f6f9fd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    border-radius: 100px;
    transform: translateY(-50%);
    font-size: 20px;
  }
</style>
<script>
export default {
    name: "details",
    mixins: [],
    props: {
        widgetName: {
            type: String,
            required: true,
        },
        entityName: {
            type: String,
            default: "prop2",
        },

        cassielid: {
            type: String,
            default: "",
        },
    },

    data: () => ({
        rawHtml: "",
        title: "",
        list: []
    }),

    mounted: function () {
        this.previewLink = process.env.VUE_APP_PREVIEW_BASE_URL;
        this.editLink = "/edit" + "/" + this.widgetName + "/" + this.entityName;

        const baseFormConfigurationUrl = this.$apiList.baseFormConfigurationAPI;
        let formConfigurationUrl =
            baseFormConfigurationUrl + "/" + this.widgetName + "/" + this.entityName;

        let cassieldId = this.cassielid;
        if (this.$route.query.cassielId) {
            cassieldId = this.$route.query.cassielId;
        }

        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
            headers["X-AUTH-TOKEN"] = token;
        }

        let bodyFormData = new FormData();
        bodyFormData.append("cassielId", cassieldId);

        this.$http({
            url: formConfigurationUrl,
            method: "POST",
            data: bodyFormData,
            headers: headers,
        }).then((response) => {
            const data = response.data.configuration;
            this.title = data[0].value;

            let dataArray = [];

            data.map((item) => {
                if (item.data !== undefined) {

                    let categoryArray = [];
                    item.data.map((itemData) => {
                        let obj = [];
                        itemData.map((itemSub) => {
                            if (itemSub.name !== undefined) {
                                if (itemSub.name.includes("Image")) obj['image_full'] = process.env.VUE_APP_FILES_BASE_URL + itemSub.value;
                                if (itemSub.name.includes("Image")) obj['image'] = itemSub.value;
                                if (itemSub.name.includes("answered")) obj['answered'] = itemSub.value;
                                if (itemSub.name.includes("title")) obj['title'] = itemSub.value;
                                if (itemSub.name.includes("content")) obj['content'] = itemSub.value;
                            }
                            else {
                                //another loop
                                let objListArray = [];

                                itemSub.data.map((itemList) => {
                                    let objList = [];
                                    itemList.map((itemListGroup) => {
                                        if (itemListGroup.name.includes("Image")) objList['image_full'] = process.env.VUE_APP_FILES_BASE_URL + itemListGroup.value;
                                        if (itemListGroup.name.includes("Image")) objList['image'] = itemListGroup.value;
                                        if (itemListGroup.name.includes("answered")) objList['answered'] = itemListGroup.value;
                                        if (itemListGroup.name.includes("title")) objList['title'] = itemListGroup.value;
                                        if (itemListGroup.name.includes("content")) objList['content'] = itemListGroup.value;
                                    })
                                    objListArray.push(objList);
                                })
                                obj['sub'] = objListArray;
                            }
                        });
                        categoryArray.push(obj);
                    });
                    dataArray.push(categoryArray)
                }
            });

            this.list = dataArray;

            this.$nextTick(function () {
            });
            this.is_data_fetched = true;
        });
    },
};
</script>
